
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import MembershipForm from '@/components/membership/MembershipForm.vue';
import { Membership } from '@/interfaces/models/Membership';
import EditMembershipLoader from '@/components/membership/EditMembershipLoader.vue';

const membership = namespace('membership');

@Component({
  components: { EditMembershipLoader, MembershipForm, VWrapper },
})
export default class EditMembership extends mixins(Editable, StackedForm, Notification) {
  @membership.State('activeMembership') public activeMembership!: Membership;

  @membership.Action('updateMembership') public update!: any;
  @membership.Action('storeMembership') public store!: any;
  @membership.Action('uploadMembershipImage') public uploadImage!: any;

  public $refs!: {
    form: InstanceType<typeof MembershipForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  get title() {
    return this.editing ? 'membership.edit' : 'membership.create';
  }

  get membership(): Membership | null {
    if (this.editing && this.activeMembership) {
      return this.activeMembership;
    }

    return null;
  }

  public async save() {
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: any = this.getMergedData();
        if (this.editing) {
          await this.update({ _id: this.activeMembership._id, ...data });
        } else {
          await this.store(data);
        }

        if (!this.activeMembership) {
          this.$router.push({ name: 'membership.index' });
          return;
        }
        let formData: FormData;
        const uploadMeta: { _id: string } = { _id: this.activeMembership._id };
        if (data.image && data.image instanceof File) {
          formData = new FormData();
          formData.append('asset', data.image as Blob);
          await this.uploadImage({ ...uploadMeta, image: formData });
        }

        this.$router.push({ name: 'membership.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  protected getMergedData() {
    return {
      ...this.$refs.form.getData(),
    };
  }
}
