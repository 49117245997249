
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import DisplayMode from '@/mixins/DisplayMode';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { namespace } from 'vuex-class';
import CustomerGroup from '@/mixins/CustomerGroup';
import Countries from '@/mixins/Countries';
import { MembershipTier } from '@/enums/MembershipTier';
import { Membership } from '@/interfaces/models/Membership';

const foodcard = namespace('foodcard');

@Component({
  components: { VOpeningHour, VFormBuilder },
})
export default class MembershipForm extends mixins(StackedForm, DisplayMode, CustomerGroup, Countries) {
  @Prop({ type: Object }) public membership!: Membership;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  get membershipTierItems(): Array<{ text: string; value: MembershipTier }> {
    const tiers: Array<{ text: string; value: MembershipTier }> = [];

    tiers.push({
      text: `${this.$t('membership.tier.membershiptier1')}`,
      value: MembershipTier.MEMBERSHIPTIER1,
    });
    tiers.push({
      text: `${this.$t('membership.tier.membershiptier2')}`,
      value: MembershipTier.MEMBERSHIPTIER2,
    });
    tiers.push({
      text: `${this.$t('membership.tier.membershiptier3')}`,
      value: MembershipTier.MEMBERSHIPTIER3,
    });
    tiers.push({
      text: `${this.$t('membership.tier.membershiptier4')}`,
      value: MembershipTier.MEMBERSHIPTIER4,
    });
    tiers.push({
      text: `${this.$t('membership.tier.membershiptier5')}`,
      value: MembershipTier.MEMBERSHIPTIER5,
    });

    return tiers;
  }

  get items() {
    return [
      {
        name: 'name',
        type: InputType.Language,
        useTextarea: true,
        label: 'membership.form.name',
        rules: 'required',
      },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'membership.form.description' },
      { name: 'image', type: InputType.File, label: 'membership.form.image', default: null, accept: 'image/*' },
      { name: 'multiplier', type: InputType.Text, label: 'membership.form.multiplier' },
      { name: 'neededPoints', type: InputType.Text, label: 'membership.form.neededPoints' },
      {
        name: 'piggyId',
        type: InputType.Select,
        label: 'membership.form.piggyId',
        items: this.membershipTierItems,
      },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'venue.form.customerGroup',
        items: this.customerGroups,
      },
    ];
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }

    // @ts-ignore
    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.image &&
        // @ts-ignore
        this.initialValues.image.thumb &&
        // @ts-ignore
        this.initialValues.image.thumb.length > 0 &&
        filesToRemove.indexOf('image') > -1
      ) {
        data.image = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
